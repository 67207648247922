import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { MAPS } from "@/game-csgo/constants.mjs";
import { getSearchParams } from "@/game-csgo/csgo-fetch-stats-data.mjs";
import Static from "@/game-csgo/static.mjs";
import GameGuideTable from "@/marketing/GameGuideTable.jsx";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const COLS = [
  {
    i18n: ["common:map", "Map"],
    dataKey: "map",
  },
  {
    i18n: ["common:playRate", "Play Rate"],
    dataKey: "playRate",
  },
  {
    i18n: ["csgo:stats.tWinRate", "T Win %"],
    dataKey: "tRoundWinRate",
  },
  {
    i18n: ["csgo:stats.ctWinRate", "CT Win %"],
    dataKey: "ctRoundWinRate",
  },
];

export default function HomeMapsTile() {
  const {
    csgo: {
      stats: { maps },
    },
  } = useSnapshot(readState);
  const { t } = useTranslation();
  const { searchParams } = useRoute();
  const key = btoa(
    new URLSearchParams(getSearchParams(searchParams)).toString(),
  );

  const data = useMemo(() => {
    if (maps[key] instanceof Error) return [];
    return Object.entries(maps[key] || {})
      .map(([mapId, stats]) => {
        return {
          map: mapId,
          playRate: stats.pick_rate * 100,
          tRoundWinRate: stats.t_win_rate * 100,
          ctRoundWinRate: stats.ct_win_rate * 100,
          matches: stats.num_matches,
          link: `/cs2/database/maps/${mapId}/overview`,
        };
      })
      .sort((a, b) => b.playRate - a.playRate);
  }, [key, maps]);

  return (
    <GameGuideTileLg
      title={["common:navigation.maps", "Maps"]}
      description={[
        "home:guides.cs2.maps.description",
        "Gain the competitive edge in CS2 with detailed map statistics and insights. Understand map strengths and weaknesses for better game strategies.",
      ]}
      buttonText={["common:navigation.viewMaps", "View Maps"]}
      href={"/cs2/stats/maps"}
    >
      <GameGuideTable className={Table()}>
        <GameGuideTable.Header>
          <div className="map-meta">{t(COLS[0].i18n)}</div>
          <div className="stat">{t(COLS[1].i18n)}</div>
          <div className="stat">{t(COLS[2].i18n)}</div>
          <div className="stat">{t(COLS[3].i18n)}</div>
        </GameGuideTable.Header>
        <GameGuideTable.Body>
          {(data || []).map((row, i) => (
            <GameGuideTable.Row key={i} link={row.link}>
              <div className={`map-meta type-subtitle--bold`}>
                <img
                  src={Static.getMapImage(row.map)}
                  width="350"
                  height="197"
                  className="map-img"
                />
                {t(MAPS[row.map]?.i18nKey, MAPS[row.map]?.name || row.map)}
              </div>
              <div className="stat type-body2-form--bold">
                {(row.playRate / 100).toLocaleString(getLocale(), {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                  style: "percent",
                })}
              </div>
              <span className="stat type-body2-form--bold">
                {(row.tRoundWinRate / 100).toLocaleString(getLocale(), {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                  style: "percent",
                })}
              </span>
              <span className="stat type-body2-form--bold">
                {(row.ctRoundWinRate / 100).toLocaleString(getLocale(), {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                  style: "percent",
                })}
              </span>
            </GameGuideTable.Row>
          ))}
        </GameGuideTable.Body>
      </GameGuideTable>
    </GameGuideTileLg>
  );
}

const Table = () => css`
  .map-meta {
    flex: 1;
    display: flex;
    align-items: center;
    gap: var(--sp-3);
    color: var(--shade0);
  }
  .map-img {
    width: 4.5rem;
    height: auto;
    aspect-ratio: 350 / 197;
    border-radius: var(--br-sm);
  }
  .map-name {
    max-width: 11ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .stat {
    display: flex;
    justify-content: center;
    width: 17%;
    white-space: nowrap;
  }
  .tier svg {
    width: var(--sp-6);
    height: var(--sp-6);
  }
`;
